import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '@fi/app/core/directives/directive.module';
import { UiModule } from '@fi/app/ui/ui.module';
import { UtentiModule } from '../utenti/utenti.module';
import { ProfileAdditionalInfoComponent } from './components/pages/additional-info.component';
import { ProfileTabsComponent } from './components/pages/profile-tabs.component';
import { ProfileComponent } from './components/pages/profile.component';
import { PROFILE_CONFIG_PROVIDER, ProfileModuleOptions } from './module.options';
import { ProfileRoutingModule } from './profile-routing.module';
@NgModule({
  declarations: [ProfileComponent, ProfileTabsComponent, ProfileAdditionalInfoComponent],
  imports: [UiModule, CommonModule, ProfileRoutingModule, UtentiModule, ReactiveFormsModule, DirectiveModule],
  providers: [],
})
export class ProfileModule {
  static forRoot(config: ProfileModuleOptions): ModuleWithProviders<ProfileRoutingModule> {
    return {
      ngModule: ProfileRoutingModule,
      providers: [
        {
          provide: PROFILE_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
